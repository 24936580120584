import React, { useState } from "react";
import { useLoadScript, GoogleMap, MarkerF } from "@react-google-maps/api";

const getDirection = async (e, supp) => {
  e.preventDefault();
  // const googleLink = "https://www.google.com/maps/dir/?api=1&destination=";
  // const position = `${supp.latitude},${supp.longtitude}`;
  // const destinationToURL = encodeURIComponent(position);
  // const destinantionLink = googleLink + destinationToURL;
  // window.open(destinantionLink, "_blank");
  const googleLink = `https://www.google.com/maps/place/${supp.latitude},${supp.longtitude}`;
  window.open(googleLink, "_blank");
};
const icon = (type) => {
  switch (type) {
      case 1:
          return "red.png"
      case 3:
          return "yellow.png"
      case 2:
          return "blue.png"
  }
}
const MapDetails = ({ supp }) => {
 
  const [mapRef, setMapRef] = useState();
  const onMapLoad = (map) => {
      setMapRef(map);
  };
  return (
    <div className="map-div-121">
        <GoogleMap
          center={{ lat: supp.latitude, lng: supp.longtitude }}
          zoom={14}
          mapContainerStyle={{ width: "100vw", height: "60vh", position: "relative" }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: true,
          }}
          onLoad={onMapLoad}
        >
          <MarkerF
            position={{ lat: supp.latitude, lng: supp.longtitude }}
            icon={icon(supp.pin_color)}
            animation={window.google.maps.Animation.BOUNCE}
          />
        </GoogleMap>
    </div>
  );
};

export default MapDetails;
